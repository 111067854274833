.header {
  background-color: white;
  display         : flex;
  position        : fixed;
  width           : 100%;
  z-index         : 10;
  top             : 0;
  left            : 0;
 border-bottom: 1px solid #80808026;
    box-shadow: 2px 2px 4px #80808024;
}

.placeholder {
  width : 100vw;
  height: 75px;
}

.logo {
  padding: 10px;
}

.nav {
  flex-grow      : 1;
  display        : flex;
  justify-content: flex-end;
  align-items    : center;
}

.nav_mobile {
  display: none;
}

.link {
  font-size: 18px;
  padding  : 5px 20px;
}

.dropdownContainer {
  position : relative;
  cursor   : pointer;
  font-size: 18px;
  padding  : 0 20px;
}

.dropdownContainer:hover {
  color: #1abc9c;
}

.dropdown {
  position        : absolute;
  width           : 205px;
  top             : 36px;
  display         : flex;
  flex-direction  : column;
  justify-content : space-around;
  align-items     : flex-start;
  border          : 1px solid grey;
  border-radius   : 5px;
  background-color: white;
  z-index         : 12;
}

.backdrop {
  height  : 100vh;
  width   : 100vw;
  position: fixed;
  top     : 0;
  left    : 0;
  z-index : 11;
  cursor  : auto;
}

@media screen and (max-width:1000px) {
  .nav {
    display: none
  }

  .nav_mobile {
    display        : block;
    flex-grow      : 1;
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
  }

  .nav_openIcon {
    font-size: 40px;
    cursor   : pointer;
  }

  .backdrop_mobile {
    height          : 100vh;
    width           : 100vw;
    position        : fixed;
    top             : 0;
    left            : 0;
    z-index         : 11;
    background-color: rgba(0, 0, 0, 0.5)
  }

  .sideDrawer {
    position        : fixed;
    width           : 75%;
    max-width       : 300px;
    height          : 100vh;
    top             : 0;
    right           : 0;
    background-color: white;
    z-index         : 12;
  }

  .links_mobile {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;
    padding-top   : 30px;
  }

  .divider {
    width : 100%;
    margin: 6px 0;
  }

  .link {
    padding: 10px 20px;
  }
}